import React from "react";
import Button from "../../Atoms/Button/Button";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { PrismicSliceLinkProps } from "../../types/PrismicSliceLinkProps";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";

interface CtaBlockProps extends PrismicSliceProps {
    primary: {
        headline: PrismicTextProps;
        link: PrismicSliceLinkProps;
        link_label: PrismicTextProps;
        text: [RichTextBlock];
    };
}

const CtaBlockSlice = React.memo((data: CtaBlockProps) => {
    const { headline, link, link_label, text } = {
        ...data.primary,
    };

    if (!headline || !link || !link_label || !text) {
        return null;
    }

    return (
        <div className="cta">
            <div className="cta__wrapper">
                <div className="cta__content">
                    <h3 className="cta__heading">{headline[0]?.text}</h3>
                    <div className="cta__text">
                        <RichText render={text} htmlSerializer={htmlSerializer} />
                    </div>
                </div>
                <div className="cta__button">
                    <Button link={link} text={link_label[0]?.text}></Button>
                </div>
            </div>
        </div>
    );
});

export type { CtaBlockProps };
export { CtaBlockSlice };
