import React from "react";
import { PrismicTextProps } from "../../types/PrismicTextProps";

interface HeadlineProps {
    headline: PrismicTextProps;
}

const Headline = (data: HeadlineProps) => {
    const { headline } = { ...data };

    if (!headline) {
        return null;
    }

    return (
        <div className="headline">
            <h1 className="headline__text">{headline[0].text}</h1>
        </div>
    );
};

export default React.memo(Headline);
