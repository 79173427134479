import React, { useEffect, useState } from "react";
import Menu from "../Menu/Menu";
import Logo from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
    const [menuIsActive, setMenuActive] = useState(false);
    const body = document.body;
    const location = useLocation();

    useEffect(() => {
        setMenuActive(false);
    }, [location]);

    useEffect(() => {
        if (body) {
            // set overflow: hidden when menu is active to prevent unwanted scrolling
            if (menuIsActive) {
                body.classList.add("menu-is-active");
            } else {
                body.classList.remove("menu-is-active");
            }
        }
    });

    return (
        <div className="container header">
            <div className="header__wrapper">
                <Link to={"/"}>
                    <img className="header__logo" src={Logo} alt="Inspire Dialogue logo"></img>
                </Link>
            </div>
            <button
                className={`header__button ${menuIsActive ? "is-active" : ""}`}
                onClick={() => setMenuActive(!menuIsActive)}
            >
                <span className="header__menu-icon"></span>
                <span className="header__menu-icon"></span>
                <span className="header__menu-icon"></span>
            </button>
            <div className={`header__menu ${menuIsActive ? "is-active" : ""}`}>
                <Menu />
            </div>
        </div>
    );
};

export default Header;
