import React from "react";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { PrismicImageProps } from "../../types/PrismicImageProps";

import Fade from "../../hooks/useFadeAnimation";

interface DialogueDetailHeroProps {
    headline: PrismicTextProps;
    sub_headline: PrismicTextProps;
    label: PrismicTextProps;
    image: PrismicImageProps;
}

const DialogueDetailHero = (data: DialogueDetailHeroProps) => {
    const { headline, sub_headline, label, image } = { ...data };

    return (
        <Fade show={!!data}>
            <div className="dialogue-detail-hero">
                <div className="dialogue-detail-hero__media" style={{ backgroundImage: `url(${image.url})` }}>
                    <span className="screenreader">{image.alt ? image.alt : headline[0]?.text}</span>
                </div>
                <div className="dialogue-detail-hero__content">
                    <p className="dialogue-detail-hero__label">{label[0]?.text}</p>
                    <h3 className="dialogue-detail-hero__headline">{headline[0]?.text}</h3>
                    <h4 className="dialogue-detail-hero__sub-headline">{sub_headline[0]?.text}</h4>
                </div>
            </div>
        </Fade>
    );
};

export default DialogueDetailHero;
