import React, { useEffect, useState } from "react";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import Headline from "../../Components/Headline/Headline";
import Loader from "../../Components/Loader/Loader";
import MetaInfo from "../../Components/MetaInfo/MetaInfo";
import SupportTheDialogueSliceRenderer from "../../Slices/SupportTheDialogueSliceRenderer";
import { PrismicDocumentProps } from "../../types/PrismicDocumentProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";

interface OurStoryProps {
    title: PrismicTextProps;
    body: [PrismicSliceProps<[]>];
    meta_title: string;
    meta_description: string;
}

const SupportTheDialoguePage = () => {
    const [supportTheDialogueData, setSupportTheDialogueData] = useState<OurStoryProps>();
    const [id, setId] = useState<string>("");

    const fetchData = async () => {
        const prismicData: PrismicDocumentProps = await prismicGetSingle({
            id: "support_the_dialogue",
            options: {},
        });
        setSupportTheDialogueData(prismicData.data);
        setId(prismicData.id);
    };

    useEffect(() => {
        if (!supportTheDialogueData) {
            fetchData();
        }
    }, [supportTheDialogueData]);

    if (supportTheDialogueData) {
        return (
            <>
                <MetaInfo
                    meta_title={supportTheDialogueData.meta_title}
                    meta_description={supportTheDialogueData.meta_description}
                />
                <Headline headline={supportTheDialogueData.title} />
                <SupportTheDialogueSliceRenderer {...supportTheDialogueData} docId={id} />
            </>
        );
    }
    return <Loader />;
};

export default SupportTheDialoguePage;
