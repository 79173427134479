import React from "react";
import { PrismicImageProps } from "../../types/PrismicImageProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";

interface ImageListSliceProps extends PrismicSliceProps<PrismicImageProps> {
    primary: {
        image_list_title: PrismicTextProps;
    };
    items: [];
}

type ImageListItem = {
    image: PrismicImageProps;
    link: {
        link_type: string;
        target: string;
        url: string;
    };
    name: PrismicTextProps;
};

const ImageListSlice = (data: ImageListSliceProps) => {
    const { image_list_title } = { ...data.primary };

    if (!data.items.length) {
        return null;
    }

    const items = data.items.map((item: ImageListItem, index) => {
        if (item.link.url) {
            return (
                <a
                    href={item.link.url}
                    target={item.link.target}
                    className="image-list__item"
                    key={`${item.image.url}_${index}`}
                    rel="noopener noreferrer"
                >
                    {item.image.url ? (
                        <img src={item.image.url} alt={item.image.alt} className="image-list__media" />
                    ) : (
                        <h3 className="image-list__link">{item.name[0]?.text}</h3>
                    )}
                </a>
            );
        } else {
            return (
                <div className="image-list__item" key={`${item.image.url}_${index}`}>
                    {item.image.url ? (
                        <img src={item.image.url} alt={item.image.alt} className="image-list__media" />
                    ) : (
                        <h3 className="image-list__link">{item.name[0]?.text}</h3>
                    )}
                </div>
            );
        }
    });

    return (
        <div className="image-list">
            <h5 className="image-list__heading">{image_list_title[0]?.text}</h5>
            <div className="image-list__container">{items}</div>
        </div>
    );
};

export type { ImageListSliceProps };
export { ImageListSlice };
