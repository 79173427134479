import React from "react";
import { PrismicImageProps } from "../../types/PrismicImageProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";

interface PersonBlockProps extends PrismicSliceProps<PersonBlockItemProps> {
    primary: {
        group: string;
        layout: boolean;
    };
}

interface PersonBlockItemProps {
    name: string;
    profile_image: PrismicImageProps;
    title: string;
    biography: PrismicTextProps;
}

const PersonBlockWithLayout = (data: { persons: PersonBlockItemProps[]; heading: string }) => {
    const persons = data.persons.map((person, index) => {
        const { name, profile_image, title } = { ...person };

        return (
            <div className="person-block__layout-item" key={"personBlockLayout" + name + index}>
                <div className="person-block__layout-media">
                    <img src={profile_image?.url} alt={name}></img>
                </div>
                <div className="person-block__layout-content">
                    <h5 className="person-block__layout-title">{name}</h5>
                    <p className="person-block__layout-subtitle">{title}</p>
                </div>
            </div>
        );
    });
    return (
        <div className="person-block">
            <h4 className="person-block__subheading">{data.heading}</h4>
            <div className="person-block__layout">{persons}</div>
        </div>
    );
};

const PersonBlock = (data: { persons: PersonBlockItemProps[]; heading: string }) => {
    const persons = data.persons.map((person, index) => {
        const { name, profile_image, title, biography } = { ...person };

        if (!name) {
            return null;
        }

        return (
            <div className="person-block__item" key={"personBlock_" + name + index}>
                <div className="person-block__media">
                    <img src={profile_image?.url} alt={name}></img>
                </div>
                <div className="person-block__content">
                    <div className="person-block__info">
                        <h5 className="person-block__title">{name}</h5>
                        <p className="person-block__subtitle">{title}</p>
                    </div>
                    <div className="person-block__text">
                        <p>{biography[0]?.text}</p>
                    </div>
                </div>
            </div>
        );
    });
    return (
        <div className="person-block">
            <h4 className="person-block__heading">{data.heading}</h4>
            <div className="person-block__list">{persons}</div>
        </div>
    );
};

const PersonBlockSlice = (data: PersonBlockProps) => {
    const { layout, group } = { ...data.primary };
    const { items } = { ...data };

    if (layout) {
        return <PersonBlockWithLayout persons={items} heading={group} />;
    } else {
        return <PersonBlock persons={items} heading={group} />;
    }
};

export type { PersonBlockProps };
export { PersonBlockSlice };
