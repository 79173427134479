import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { linkResolver } from "../prismic-configuration";

interface SubNavItemProps {
    sub_nav_link: {
        id: string;
        type: string;
        isBroken: boolean;
        link_type: string;
        slug: string;
        uid: string;
    };
    sub_nav_link_label: [
        {
            text: string;
        }
    ];
}

interface NavItemProps {
    items: [SubNavItemProps];
    slice_type: string;
    primary: {
        label: [
            {
                text: string;
            }
        ];
        link: {
            id: string;
            type: string;
            isBroken: boolean;
            link_type: string;
            slug: string;
            uid: string;
        };
    };
}

// skip nav_item_without_sub as they, of course, do not have sub navigation
const hasSubItems = (menuItem: NavItemProps) => {
    if (menuItem.slice_type === "nav_item" && menuItem.items.length > 0) {
        return menuItem.items[0].sub_nav_link_label.length > 0;
    }
};

const NavItemSlice = (data: NavItemProps) => {
    const [isOpen, setIsOpen] = useState(true);
    const [listHeight, setListHeight] = useState<string>("0px");

    const location = useLocation();

    const { items, primary } = {
        ...data,
    };

    const navItemRef = React.createRef<HTMLUListElement>();

    useEffect(() => {
        if (navItemRef.current) {
            const itemHeight = navItemRef.current?.offsetHeight;
            setListHeight(itemHeight + "px");
        }
    }, [navItemRef, data]);

    useEffect(() => {
        if (listHeight) {
            setIsOpen(false);
        }
    }, [listHeight]);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const menuItemHasSubItems = hasSubItems(data);
    const menuItemData = primary;

    const isActive = location.pathname.includes(linkResolver(menuItemData.link));
    let subLevelItems;

    if (menuItemHasSubItems) {
        subLevelItems = items.map((menuSubItem: SubNavItemProps, index: number) => {
            return (
                <li className="menu__sub-item" key={menuSubItem.sub_nav_link.id + menuSubItem.sub_nav_link.uid + index}>
                    <Link to={linkResolver(menuSubItem.sub_nav_link)} className="menu__link menu__link--sub">
                        {menuSubItem.sub_nav_link_label[0].text}
                    </Link>
                </li>
            );
        });
    }

    return (
        <li
            className={`menu__item ${!subLevelItems ? "menu__item--no-sub" : ""} ${isOpen ? "is-open" : "is-closed"} ${
                isActive ? "link-is-active" : ""
            }`}
        >
            <>
                <Link to={linkResolver(menuItemData.link)} className={"menu__link"}>
                    {menuItemData.label[0].text}
                </Link>
                {subLevelItems && (
                    <>
                        <button className="menu__button" onClick={toggleAccordion}></button>
                        <ul className="menu__accordion" ref={navItemRef}>
                            {subLevelItems}
                        </ul>
                    </>
                )}
            </>
        </li>
    );
};

export type { NavItemProps };
export { NavItemSlice };
