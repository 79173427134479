import React from "react";
import { TextAndImageBlockProps, TextAndImageBlockSlice } from "./TextAndImageBlockSlice/TextAndImageBlockSlice";
import { ImageGalleryProps, ImageGallerySlice } from "./ImageGallerySlice/ImageGallerySlice";
import { QuoteSliceProps, QuoteSlice } from "./QuoteSlice/QuoteSlice";
import { LinkListProps, LinkListSlice } from "./LinkListSlice/LinkListSlice";
import { CtaBlockProps, CtaBlockSlice } from "./CtaBlockSlice/CtaBlockSlice";
import { ColumnTextProps, ColumnTextSlice } from "./ColumnTextSlice/ColumnTextSlice";
import Fade from "../hooks/useFadeAnimation";
import { PrismicSliceProps } from "../types/PrismicSliceProps";

interface DialogueDetailSliceRendererProps {
    body: [PrismicSliceProps<[]>];
    docId: string;
}

const DialogueDetaiLSliceRenderer = (data: DialogueDetailSliceRendererProps) => {
    // this is an any type, because Prismic typed doc data as any
    let content = data.body.map((slice, index) => {
        switch (slice.slice_type) {
            case "text_and_image_block":
                const dialogueBlockData = slice as TextAndImageBlockProps;
                return (
                    <Fade show={!!dialogueBlockData} key={data.docId + slice.slice_type + index}>
                        <TextAndImageBlockSlice {...dialogueBlockData} />
                    </Fade>
                );
            case "image_gallery":
                const ImageGalleryData = slice as ImageGalleryProps;
                return (
                    <Fade show={!!ImageGalleryData} key={data.docId + slice.slice_type + index}>
                        <ImageGallerySlice {...ImageGalleryData} {...data.docId} />
                    </Fade>
                );
            case "quote":
                const QuoteData = slice as QuoteSliceProps;
                return (
                    <Fade show={!!QuoteData} key={data.docId + slice.slice_type + index}>
                        <QuoteSlice {...QuoteData} {...data.docId} />
                    </Fade>
                );
            case "link_list":
                const LinkListData = slice as LinkListProps;
                return (
                    <Fade show={!!LinkListData} key={data.docId + slice.slice_type + index}>
                        <LinkListSlice {...LinkListData} {...data.docId} />
                    </Fade>
                );
            case "cta_block":
                const CtaBlockData = slice as CtaBlockProps;
                return (
                    <Fade show={!!CtaBlockData} key={data.docId + slice.slice_type + index}>
                        <CtaBlockSlice {...CtaBlockData} {...data.docId} />
                    </Fade>
                );
            case "one_column_text":
            case "two_column_text_block":
                const ColumnTextData = slice as ColumnTextProps;
                return (
                    <Fade show={!!ColumnTextData} key={data.docId + slice.slice_type + index}>
                        <ColumnTextSlice {...ColumnTextData} {...data.docId} />
                    </Fade>
                );
            default:
                return <h1>No matching tsx found for {slice.slice_type}</h1>;
        }
    });

    return <>{content}</>;
};

export default DialogueDetaiLSliceRenderer;
