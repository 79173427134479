import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import Loader from "../../Components/Loader/Loader";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";

interface ErrorPageProps {
    title: PrismicTextProps;
    paragraph: [RichTextBlock];
}

const ErrorPage = () => {
    const history = useHistory();
    const [doc, setDocData] = useState<ErrorPageProps>();

    useEffect(() => {
        if (!doc) {
            fetchData();
        }
    }, [doc]);

    const fetchData = async () => {
        const prismicData = await prismicGetSingle({ id: "errorpage", options: {} });
        setDocData(prismicData.data);
    };

    if (doc) {
        const { title, paragraph } = { ...doc };
        return (
            <>
                <div className="errorpage-container">
                    <div className="heaedline">
                        <h1 className="headline__text"> {title[0].text} </h1>
                    </div>
                    <RichText render={paragraph} htmlSerializer={htmlSerializer} />
                    <button
                        className="button button--pri"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Take me back
                    </button>
                </div>
            </>
        );
    }
    return <Loader />;
};
export default ErrorPage;
