import React from "react";
import {
    DialogueHighlightBlockProps,
    DialogueHighlightBlockSlice,
} from "./DialogueHighlightBlockSlice/DialogueHighlightBlockSlice";
import { CtaBlockProps, CtaBlockSlice } from "./CtaBlockSlice/CtaBlockSlice";
import { ShortInfoSliceProps, ShortInfoSlice } from "./ShortInfoSlice/ShortInfoSlice";
import { TextAndImageBlockProps, TextAndImageBlockSlice } from "./TextAndImageBlockSlice/TextAndImageBlockSlice";
import Fade from "../hooks/useFadeAnimation";
import { PrismicSliceProps } from "../types/PrismicSliceProps";

interface SliceRendererProps {
    body: [PrismicSliceProps<[]>];
    docId: string;
}

const SliceRenderer = (data: SliceRendererProps) => {
    let content = data.body.map((slice, index) => {
        switch (slice.slice_type) {
            case "dialogue_block":
                //Dialogue block is actually the same as DialogueHighlightBlock...
                const dialogueBlockData = slice as DialogueHighlightBlockProps;
                return (
                    <Fade show={!!dialogueBlockData} key={data.docId + slice.slice_type + index}>
                        <DialogueHighlightBlockSlice {...dialogueBlockData} />
                    </Fade>
                );
            case "dialogue_highlight_block":
                const dialogueHighlightData = slice as DialogueHighlightBlockProps;
                return (
                    <Fade show={!!dialogueHighlightData} key={data.docId + slice.slice_type + index}>
                        <DialogueHighlightBlockSlice {...dialogueHighlightData} />
                    </Fade>
                );
            case "cta_block":
                const ctaData = slice as CtaBlockProps;
                return (
                    <Fade show={!!ctaData} key={data.docId + slice.slice_type + index}>
                        <CtaBlockSlice {...ctaData} />
                    </Fade>
                );
            case "short_info":
                const shortInfo = slice as ShortInfoSliceProps;
                return (
                    <Fade
                        show={!!shortInfo}
                        key={data.docId + slice.slice_type + index}
                        extraClass="short-info-wrapper"
                    >
                        <ShortInfoSlice {...shortInfo} />
                    </Fade>
                );
            case "text_and_image_block":
                const textAndImageBlockData = slice as TextAndImageBlockProps;
                return (
                    <Fade show={!!textAndImageBlockData} key={data.docId + slice.slice_type + index}>
                        <TextAndImageBlockSlice {...textAndImageBlockData} />
                    </Fade>
                );
            default:
                console.log(`No matching slice was found for ${slice.slice_type}`);
                return null;
        }
    });

    return <>{content}</>;
};

export default SliceRenderer;
