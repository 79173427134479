import React, { useEffect, useState } from "react";
import { Document as PrismicDocument } from "prismic-javascript/d.ts/documents"; //There is a React Document and a Prismic Document = namespace clash

import HomepageHero from "./Components/HomepageHero";
import HomepageIntro from "./Components/HomepageIntro";
import SliceRenderer from "../../Slices/SliceRenderer";

import Fade from "../../hooks/useFadeAnimation";
import Loader from "../../Components/Loader/Loader";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import MetaInfo from "../../Components/MetaInfo/MetaInfo";

const Homepage = () => {
    const [doc, setDocData] = useState<PrismicDocument>();

    useEffect(() => {
        if (!doc) {
            fetchData();
        }
    }, [doc]);

    const fetchData = async () => {
        const prismicData = await prismicGetSingle({ id: "homepage_i", options: {} });
        setDocData(prismicData);
    };

    let content;

    //if there is no body, then there is no data for Slices
    if (doc?.data.body) {
        content = <SliceRenderer body={doc.data.body} docId={doc.id} />;
    }

    if (content != null && doc?.data != null) {
        const metaTitle = doc.data.meta_title;
        const metaDescription = doc.data.meta_description;

        return (
            <>
                <MetaInfo meta_title={metaTitle} meta_description={metaDescription} />
                <div className="homepage">
                    <Fade show={!!doc?.data} key={doc?.id + "HomepageHero"}>
                        <HomepageHero {...doc?.data} />
                        <HomepageIntro {...doc?.data} />
                    </Fade>
                    {content}
                </div>
            </>
        );
    }

    return <Loader />;
};

export default Homepage;
