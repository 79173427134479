import React from "react";

type textProp = [{ text: string }];

interface HomepageHeroProps {
    headline_1: textProp;
    headline_2: textProp;
    quote: textProp;
    quote_author: textProp;
    hero_image: {
        url: string;
        alt: string;
    };
}

const HomepageHero = (data: HomepageHeroProps) => {
    if (!data) {
        return null;
    }

    const { headline_1, headline_2, quote, quote_author, hero_image } = { ...data };

    if (!headline_1 || !headline_2 || !hero_image) {
        return null;
    }

    return (
        <div className="homepage-hero">
            <div className="homepage-hero__header">
                <p className="homepage-hero__subheading">{headline_1[0]?.text}</p>
                <h1 className="homepage-hero__heading">{headline_2[0]?.text}</h1>
            </div>
            <div className="homepage-hero__media">
                <img src={hero_image?.url} alt={hero_image?.alt} />
            </div>
            <div className="homepage-hero__text">
                <div className="homepage-hero__text-wrapper">
                    <blockquote className="homepage-hero__quote">{quote[0]?.text}</blockquote>
                    <small className="homepage-hero__byline">{quote_author[0]?.text}</small>
                </div>
            </div>
        </div>
    );
};

export default HomepageHero;
