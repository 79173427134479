import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Atoms/Button/Button";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { PrismicImageProps } from "../../types/PrismicImageProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicSliceLinkProps } from "../../types/PrismicSliceLinkProps";

interface DialogueHighlightBlockProps extends PrismicSliceProps {
    primary: {
        headline: PrismicTextProps;
        sub_headline: PrismicTextProps;
        link: PrismicSliceLinkProps;
        button_label: string;
        label: PrismicTextProps;
        image_alignment: string;
        image: PrismicImageProps;
    };
}

const DialogueHighlightBlockSlice = React.memo((data: DialogueHighlightBlockProps) => {
    const { headline, sub_headline, link, button_label, label, image, image_alignment } = {
        ...data.primary,
    };

    const sliceType = data.slice_type;

    if (!headline || !link || !button_label) {
        return null;
    }

    return (
        <div className={`dialogue-block is-` + image_alignment.toLocaleLowerCase()}>
            <div className="dialogue-block__wrapper">
                <div className="dialogue-block__media">
                    <img
                        alt={image.alt ? image.alt : headline[0].text}
                        className="dialogue-block__image"
                        src={image.url}
                        width={image.dimensions.width}
                        height={image.dimensions.height}
                    />
                </div>
                <div className="dialogue-block__content">
                    <p className="dialogue-block__byline">{label[0]?.text}</p>
                    <h3 className="dialogue-block__title">{headline[0]?.text}</h3>
                    <h4 className="dialogue-block__subtitle">{sub_headline[0]?.text}</h4>
                    <Button link={link} text={button_label} color={"secondary"}></Button>
                </div>
            </div>
            {sliceType === "dialogue_highlight_block" && (
                <Link className="dialogue-block__cta" to={"/dialogues"}>
                    View all dialogues
                </Link>
            )}
        </div>
    );
});

export type { DialogueHighlightBlockProps };
export { DialogueHighlightBlockSlice };
