import React from "react";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";

interface QuoteSliceProps extends PrismicSliceProps {
    primary: {
        quote: PrismicTextProps;
        author: PrismicTextProps;
        author_byline: PrismicTextProps;
    };
}

const QuoteSlice = React.memo((props: QuoteSliceProps) => {
    const { quote, author, author_byline } = { ...props.primary };

    if (!quote || !author || !author_byline) {
        return null;
    }

    return (
        <div className="quote">
            <blockquote className="quote__text">{quote[0]?.text}</blockquote>
            <h6 className="h6 quote__author">{author[0]?.text}</h6>
            {author_byline?.length ? <p className="quote__byline">{author_byline[0]?.text}</p> : null}
        </div>
    );
});

export type { QuoteSliceProps };
export { QuoteSlice };
