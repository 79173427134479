import React, { useEffect, useState } from "react";
import { Document as PrismicDocument } from "prismic-javascript/d.ts/documents"; //There is a React Document and a Prismic Document = namespace clash
import { Link } from "react-router-dom";
import Icon from "../../Atoms/Icon/Icon";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";
import { linkResolver } from "../../prismic-configuration";

type FooterLinksProps = {
    link: {
        id: string;
        isBroken: boolean;
        link_type: string;
        slug: string;
        uid: string;
    };
    link_name: string;
};

interface FooterProps {
    headline: [
        {
            text: string;
        }
    ];
    contact_text: [RichTextBlock];
    footer_links: FooterLinksProps[];
    social_links: [
        {
            social_label: [
                {
                    text: string;
                }
            ];
            social_url: {
                link_type: string;
                target: string;
                url: string;
            };
        }
    ];
}

const FooterContent = (data: FooterProps) => {
    const { headline, contact_text, footer_links, social_links } = { ...data };

    const contactText = <RichText render={contact_text} htmlSerializer={htmlSerializer} />;

    const footerLinks = footer_links.map((linkItem, index) => {
        return (
            <li key={linkItem.link.uid + "_" + linkItem.link.slug + "_" + index}>
                <Link to={linkResolver(linkItem.link)} className={"footer__link"}>
                    {linkItem.link_name}
                </Link>
            </li>
        );
    });

    const socialLinks = social_links.map((socialItem, index) => {
        return (
            <a
                key={socialItem.social_label[0].text + "_" + socialItem.social_url.url + "_" + index}
                className="footer__social-item"
                href={socialItem.social_url.url}
                target={socialItem.social_url.target}
            >
                <span className="screenreader">{socialItem.social_label[0].text}</span>
                <Icon name={socialItem.social_label[0].text.toLowerCase()} color={"#fff"} sizeW={18} sizeH={18}></Icon>
            </a>
        );
    });

    return (
        <>
            <div className="footer__content">
                <div className="footer__heading">
                    <h3 className="footer__headline">{headline[0].text}</h3>
                </div>
                <div className="footer__text">{contactText}</div>
            </div>
            <ul className="footer__links">{footerLinks}</ul>
            <div className="footer__social">{socialLinks}</div>
        </>
    );
};

const Footer = () => {
    const [footer, setFooter] = useState<PrismicDocument>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const prismicData = await prismicGetSingle({ id: "footer", options: {} });
        setFooter(prismicData);
    };

    if (footer) {
        return (
            <div className="footer">
                <div className="footer__wrapper container ">
                    <FooterContent {...footer.data} />
                </div>
            </div>
        );
    } else {
        return (
            <div className="container footer">
                <div className="footer__wrapper"></div>
            </div>
        );
    }
};

export default Footer;
