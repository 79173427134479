import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";
import MetaInfo from "../../Components/MetaInfo/MetaInfo";

interface ContactUsProps {
    title: PrismicTextProps;
    paragraph: [RichTextBlock];
    meta_title: string;
    meta_description: string;
}

const ContactUsPage = () => {
    const [doc, setDocData] = useState<ContactUsProps>();
    useEffect(() => {
        if (!doc) {
            fetchData();
        }
    }, [doc]);

    const fetchData = async () => {
        const prismicData = await prismicGetSingle({ id: "contact_us", options: {} });
        setDocData(prismicData.data);
    };

    if (doc) {
        const { title, paragraph, meta_title, meta_description } = { ...doc };

        return (
            <>
                <MetaInfo meta_title={meta_title} meta_description={meta_description} />
                <div className="contact-page">
                    <div className="contact-page__textcontainer">
                        <h1 className="contact-page__header"> {title[0]?.text} </h1>
                        <RichText render={paragraph} htmlSerializer={htmlSerializer} />
                    </div>
                    <div className="contact-page__wrapper">
                        <iframe
                            title="iFrameMailChimpSubscribe"
                            id="iFrameMailChimpSubscribe"
                            name="iFrameMailChimpSubscribe"
                            scrolling="no"
                            src="https://eepurl.com/dk1GHL "
                            height="1200px"
                        ></iframe>
                    </div>
                </div>
            </>
        );
    }
    return <Loader />;
};

export default ContactUsPage;
