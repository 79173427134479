import React, { Fragment } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import Homepage from "../Pages/Homepage/Homepage";
import DialoguesPage from "../Pages/Dialogues/Dialogues";
import OurStoryPage from "../Pages/OurStory/OurStory";
import SupportTheDialoguePage from "../Pages/SupportTheDialogue/SupportTheDialogue";
import DialogueDetail from "../Pages/DialogueDetail/DialogueDetail";
import DonatePage from "../Pages/Donate/Donate";
import ContactUsPage from "../Pages/ContactUs/ContactUsPage";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import ScrollToTop from "../HoC/ScrollToTop";
import "../style/main.scss";
import MetaInfo from "../Components/MetaInfo/MetaInfo";

/*
  Main application component
*/
const App = () => {
    return (
        <Fragment>
            <MetaInfo
                meta_title={""}
                meta_description={
                    "Dialogue is an annual themed conference and platform, focusing around creating a conversation where one plus one equals three. We invite thinkers, scholars, innovators – young  and old from all over the world to listen, share and find common ground. Previous topics have included the genocide of the Tutsi, climate change and our universal responsibility, a topic headlined by keynote speaker his holiness the Dalai Lama."
                }
            />
            <BrowserRouter>
                <div className="page">
                    <ScrollToTop>
                        <div className="content-wrapper">
                            <Header />
                            <div className="container main">
                                <Switch>
                                    <Route exact path="/" component={Homepage} />
                                    <Route exact path="/dialogues" component={DialoguesPage} />
                                    <Route exact path="/our-story" component={OurStoryPage} />
                                    <Route exact path="/support-the-dialogue" component={SupportTheDialoguePage} />
                                    <Route exact path="/donate" component={DonatePage} />
                                    <Route exact path="/contact-us" component={ContactUsPage} />
                                    <Route exact path="/404-page" component={ErrorPage} />
                                    <Route path="/dialogues/:slug" component={DialogueDetail} />
                                    <Redirect from="*" to="/404-page" />
                                </Switch>
                            </div>
                        </div>
                    </ScrollToTop>
                    <Footer />
                </div>
            </BrowserRouter>
        </Fragment>
    );
};

export default App;
