import React from "react";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { isPrismicTextPropValid } from "../../ApiHelpers/validatePrismicTextProp";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";

interface ShortInfoSliceProps extends PrismicSliceProps {
    primary: {
        title: PrismicTextProps;
        text: [RichTextBlock];
    };
}

const ShortInfoSlice = React.memo((data: ShortInfoSliceProps) => {
    const { title, text } = {
        ...data.primary,
    };

    if (!isPrismicTextPropValid(title)) {
        return null;
    }

    return (
        <div className="short-info">
            <div className="short-info__wrapper">
                <h3 className="short-info__heading">{title[0].text}</h3>
            </div>
            <div className="short-info__text">
                <RichText render={text} htmlSerializer={htmlSerializer} />
            </div>
        </div>
    );
});

export type { ShortInfoSliceProps };
export { ShortInfoSlice };
