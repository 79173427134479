import React from "react";
import { PrismicTextProps } from "../../../types/PrismicTextProps";

interface HomepageIntroProps {
    intro_headline: PrismicTextProps;
    intro_text: PrismicTextProps;
}

const HomepageIntro = (data: HomepageIntroProps) => {
    if (!data) {
        return null;
    }

    const { intro_headline, intro_text } = { ...data };

    if (!intro_headline || !intro_text) {
        return null;
    }

    return (
        <div className="homepage-intro">
            <div className="homepage-intro__content">
                <h3 className="homepage-intro__heading">{intro_headline[0]?.text}</h3>
                <p className="homepage-intro__text">{intro_text[0]?.text}</p>
            </div>
        </div>
    );
};

export default HomepageIntro;
