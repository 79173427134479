import React, { useEffect, useState } from "react";
import { PrismicDocumentProps } from "../../types/PrismicDocumentProps";
import Headline from "../../Components/Headline/Headline";
import Loader from "../../Components/Loader/Loader";
import SliceRenderer from "../../Slices/SliceRenderer";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import MetaInfo from "../../Components/MetaInfo/MetaInfo";

const DialoguesPage = () => {
    const [dialoguesPage, setDialoguesPage] = useState<PrismicDocumentProps>();

    useEffect(() => {
        if (!dialoguesPage) {
            fetchData();
        }
    }, [dialoguesPage]);

    const fetchData = async () => {
        const prismicData = await prismicGetSingle({ id: "dialogues", options: {} });
        setDialoguesPage(prismicData);
    };

    if (dialoguesPage) {
        const { headline, meta_title, meta_description, ...data } = dialoguesPage.data;
        return (
            <>
                <MetaInfo meta_title={meta_title} meta_description={meta_description} />
                <Headline headline={headline} />
                <SliceRenderer body={data.body} docId={dialoguesPage.id} />
            </>
        );
    } else {
        return <Loader />;
    }
};

export default DialoguesPage;
