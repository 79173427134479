import React from "react";
import Fade from "../hooks/useFadeAnimation";
import { PrismicSliceProps } from "../types/PrismicSliceProps";
import { ShortInfoSlice, ShortInfoSliceProps } from "./ShortInfoSlice/ShortInfoSlice";
import { PersonBlockSlice, PersonBlockProps } from "./PersonBlockSlice/PersonBlockSlice";

interface OurStorySliceRendererProps {
    body: [PrismicSliceProps<[]>];
    docId: string;
}

const OurStorySliceRenderer = (data: OurStorySliceRendererProps) => {
    const content = data.body.map((slice, index) => {
        switch (slice.slice_type) {
            case "short_info":
                const shortInfo = slice as ShortInfoSliceProps;
                return (
                    <Fade
                        show={!!shortInfo}
                        key={data.docId + slice.slice_type + index}
                        extraClass="short-info-wrapper"
                    >
                        <ShortInfoSlice {...shortInfo} />
                    </Fade>
                );
            case "person_block":
                const personBlockData = slice as PersonBlockProps;
                return (
                    <Fade show={!!personBlockData} key={data.docId + slice.slice_type + index}>
                        <PersonBlockSlice {...personBlockData} />
                    </Fade>
                );
            default:
                return null;
        }
    });

    return <>{content}</>;
};

export default OurStorySliceRenderer;
