import React from "react";
import { Helmet } from "react-helmet";
import metaImage from "../../assets/images/meta_default.png";

interface MetaInfoProps {
    meta_title: string;
    meta_description: string;
}

const MetaInfo = (data: MetaInfoProps) => {
    const { meta_title, meta_description } = { ...data };

    const title = meta_title ? `${meta_title} - Inspire Dialogue Foundation` : `Inspire Dialogue Foundation`;
    const description = meta_description
        ? `${meta_description}`
        : `Dialogue is an annual themed conference and platform, focusing around creating a conversation where one plus one equals three. We invite thinkers, scholars, innovators – young  and old from all over the world to listen, share and find common ground. Previous topics have included the genocide of the Tutsi, climate change and our universal responsibility, a topic headlined by keynote speaker his holiness the Dalai Lama.`;
    const currentUrl = window.location.href;

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />

            <meta property="og:type" content="website" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={metaImage} />

            <meta property="twitter:card" content="summary" />
            <meta property="twitter:url" content={currentUrl} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={metaImage} />
            <script
                async
                defer
                src="https://static.cdn.prismic.io/prismic.js?new=true&repo=inspiredialoguecom"
            ></script>
        </Helmet>
    );
};

export default MetaInfo;
