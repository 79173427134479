import React, { useEffect, useState } from "react";
import { Document as PrismicDocument } from "prismic-javascript/d.ts/documents"; //There is a React Document and a Prismic Document = namespace clash
import { useParams } from "react-router-dom";
import ApiSearchResponse from "prismic-javascript/d.ts/ApiSearchResponse";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { PrismicImageProps } from "../../types/PrismicImageProps";

import DialogueDetailHero from "./DialogueDetailHero";
import Loader from "../../Components/Loader/Loader";
import DialogueDetailSliceRenderer from "../../Slices/DialogueDetailSliceRenderer";

import { prismicGetByType } from "../../ApiHelpers/prismicGetByType";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import MetaInfo from "../../Components/MetaInfo/MetaInfo";

interface RouteParams {
    slug: string;
}

interface DialogueDetailProps extends PrismicSliceProps<DialogueDetailProps> {
    headline: PrismicTextProps;
    sub_headline: PrismicTextProps;
    label: PrismicTextProps;
    image: PrismicImageProps;
    body: [PrismicSliceProps<[]>];
    docId: string;
    meta_title: string;
    meta_description: string;
}

function getCorrespondingPageFromResults(slug: string, data: ApiSearchResponse) {
    const results = data.results;
    return results.filter((item) => {
        return item.uid === slug;
    });
}

const PageNotFound = () => {
    return <h1>Page not found</h1>;
};

const DialogueDetailContent = (data: DialogueDetailProps) => {
    const { headline, sub_headline, image, label, meta_title, meta_description } = { ...data };

    return (
        <>
            <MetaInfo meta_title={meta_title} meta_description={meta_description} />
            <DialogueDetailHero headline={headline} sub_headline={sub_headline} image={image} label={label} />
            <DialogueDetailSliceRenderer {...data} docId={data.docId} />
        </>
    );
};

/*
    usePrismisGetByType returns an ApiSearchResponse which has an array of results. In this array 1 item should be the corresponding object
    Since we know on what slug(uid) we are, we find the item with the same UID and set that as data for this component
*/
const DialogueDetail = () => {
    const [DialoguePage, setDialoguePage] = useState<PrismicDocument>();
    const [AllDialoguePages, setAllDialoguePages] = useState<ApiSearchResponse>();
    const [IsPageNotFound, setPageIsNotFound] = useState(false);
    const { slug } = useParams<RouteParams>();

    useEffect(() => {
        if (!AllDialoguePages) {
            fetchData();
        }
    }, [AllDialoguePages]);

    const fetchData = async () => {
        const prismicData = await prismicGetByType({ types: new Set(["dialogue_page"]), options: {} });
        setAllDialoguePages(prismicData);
    };

    useEffect(() => {
        if (AllDialoguePages && slug) {
            const dialogueDetailDocument = getCorrespondingPageFromResults(slug, AllDialoguePages);

            /*
                there should always be exactly one item. If somehow more than 1, then we have duplicate UIDs which should not happen.
                If it is 0, then no page has been found
            */

            if (dialogueDetailDocument.length === 1) {
                const dialogueDetailData = { ...dialogueDetailDocument[0] };
                setDialoguePage(dialogueDetailData);
            } else {
                setPageIsNotFound(true);
            }
        }
    }, [slug, AllDialoguePages]);

    if (IsPageNotFound) {
        return <PageNotFound />;
    } else if (DialoguePage) {
        return <DialogueDetailContent {...DialoguePage.data} docId={DialoguePage.id} />;
    }

    return <Loader />;
};

export default DialogueDetail;
