import React, { useEffect, useState } from "react";
import { PrismicDocumentProps } from "../../types/PrismicDocumentProps";
import { NavItemSlice, NavItemProps } from "../../Slices/NavItem";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import Loader from "../../Components/Loader/Loader";

const menuItems = (menu: PrismicDocumentProps) => {
    const topLevelItems = menu.data.nav.map((menuItem: NavItemProps, index: number) => {
        return <NavItemSlice {...menuItem} key={menuItem.primary.link.id + menuItem.primary.link.uid + index} />;
    });
    return topLevelItems;
};

const Menu = () => {
    const [menu, setMenu] = useState<PrismicDocumentProps>();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const prismicData = await prismicGetSingle({ id: "menu_v2", options: {} });
        setMenu(prismicData);
    };

    if (menu) {
        const menuElement = menuItems(menu);
        return (
            <div className="menu">
                <ul className="menu-list">{menuElement}</ul>
            </div>
        );
    } else {
        return <Loader />;
    }
};

export default Menu;
