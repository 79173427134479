import React from "react";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { PrismicSliceLinkProps } from "../../types/PrismicSliceLinkProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import Button from "../../Atoms/Button/Button";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";

interface CtaColumnSliceProps extends PrismicSliceProps<CtaColumnItemProps> {
    items: [];
    id: string;
}

type CtaColumnItemProps = {
    cta_title: PrismicTextProps;
    cta_text: [RichTextBlock];
    cta_link: PrismicSliceLinkProps;
    cta_link_text: PrismicTextProps;
};

const CtaColumnItem = (data: CtaColumnItemProps) => {
    const { cta_title, cta_text, cta_link, cta_link_text } = { ...data };

    if (!cta_title || !cta_text || !cta_link) {
        return null;
    }

    return (
        <div className="cta-column-item">
            <div className="cta-column-item__heading">
                <h4 className="cta-column-item__title">{cta_title[0]?.text}</h4>
            </div>

            <div className="cta-column-item__content">
                <div className="cta-column-item__text">
                    <RichText render={cta_text} htmlSerializer={htmlSerializer} />
                </div>
                <Button link={cta_link} text={cta_link_text[0]?.text} color="secondary"></Button>
            </div>
        </div>
    );
};

const CtaColumnSlice = (data: CtaColumnSliceProps, id: string) => {
    const items = data.items.map((item, index) => {
        return <CtaColumnItem {...item} key={id + data.slice_type + index} />;
    });

    return <div className="cta-column">{items}</div>;
};

export type { CtaColumnSliceProps };
export { CtaColumnSlice };
