import React from "react";
import { PrismicImageProps } from "../../types/PrismicImageProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";

interface HeroProps {
    hero_text: [RichTextBlock];
    hero_title: PrismicTextProps;
    hero_image: PrismicImageProps;
    image_alignment: string;
}

const Hero = (data: HeroProps) => {
    const imageAlignment = data.image_alignment.toLowerCase();
    return (
        <div className={`hero ${imageAlignment === "right" ? "is-right" : ""}`}>
            <div className="hero__media">
                <img className="hero__image" src={data.hero_image.url} alt=""></img>
            </div>
            <div className="hero__content">
                <h3 className="hero__title">{data.hero_title[0].text}</h3>
                <div className="hero__text">
                    <RichText render={data.hero_text} htmlSerializer={htmlSerializer} />
                </div>
            </div>
        </div>
    );
};

export default Hero;
