import React, { useEffect, useState } from "react";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import { PrismicDocumentProps } from "../../types/PrismicDocumentProps";
import { PrismicImageProps } from "../../types/PrismicImageProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import Headline from "../../Components/Headline/Headline";
import Hero from "../../Components/Hero/Hero";
import OurStorySliceRenderer from "../../Slices/OurStorySliceRenderer";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import Loader from "../../Components/Loader/Loader";
import { RichTextBlock } from "prismic-reactjs";
import MetaInfo from "../../Components/MetaInfo/MetaInfo";

interface OurStoryProps {
    headline: PrismicTextProps;
    hero_text: [RichTextBlock];
    hero_title: PrismicTextProps;
    hero_image: PrismicImageProps;
    image_alignment: string;
    body: [PrismicSliceProps<[]>];
    meta_title: string;
    meta_description: string;
}

const OurStoryPage = () => {
    const [ourStoryData, setOurStoryData] = useState<OurStoryProps>();
    const [id, setId] = useState<string>("");

    useEffect(() => {
        if (!ourStoryData) {
            fetchData();
        }
    }, [ourStoryData]);

    const fetchData = async () => {
        const prismicData: PrismicDocumentProps = await prismicGetSingle({ id: "our_story", options: {} });
        setOurStoryData(prismicData.data);
        setId(prismicData.id);
    };

    if (ourStoryData) {
        // https://codeburst.io/use-es2015-object-rest-operator-to-omit-properties-38a3ecffe90
        // Split the destructuring, so that we dont pass too many props to a component that wont use them anyway
        const { headline, meta_title, meta_description, ...heroProps } = ourStoryData;

        return (
            <>
                <MetaInfo meta_title={meta_title} meta_description={meta_description} />
                <Headline headline={headline} />
                <Hero {...heroProps} />
                <OurStorySliceRenderer {...ourStoryData} docId={id} />
            </>
        );
    }

    return <Loader />;
};

export default OurStoryPage;
