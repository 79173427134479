import React from "react";
import Icon from "../../Atoms/Icon/Icon";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";

interface LinkListProps extends PrismicSliceProps<LinkListItemProps> {
    primary: {
        heading: PrismicTextProps;
        text: PrismicTextProps;
    };
    items: [];
}

interface LinkListItemProps {
    link_file: {
        name: string;
        url: string;
        link_type: string;
    };
    link_name: PrismicTextProps;
    link_text: PrismicTextProps;
    link_page: {
        link_type: string;
        target: string;
        url: string;
    };
}

const isMediaLink = (item: LinkListItemProps) => {
    const { link_file } = { ...item };
    if (link_file.link_type === "Media") {
        return true;
    }
    return false;
};

const isExternalWebLink = (item: LinkListItemProps) => {
    const { link_page } = { ...item };
    if (link_page.link_type === "Web") {
        return true;
    }
    return false;
};

const LinkListItem = (item: LinkListItemProps) => {
    let itemUrl = "";
    let isExternal = false;

    if (isExternalWebLink(item)) {
        itemUrl = item.link_page.url;
        isExternal = true;
    } else if (isMediaLink(item)) {
        itemUrl = item.link_file.url;
    }

    return (
        <li className="linklist-item ">
            <div className="linklist-item__content">
                <h4 className="linklist-item__title">{item.link_name[0]?.text}</h4>
                <p className="linklist-item__text">{item.link_text[0]?.text}</p>
            </div>
            <a href={itemUrl} className="linklist-item__button" target="_blank" rel="noopener noreferrer">
                <Icon
                    name={"arrow-down"}
                    color={"#283044"}
                    sizeW={18}
                    sizeH={18}
                    extraClass={isExternal ? "is-external" : ""}
                ></Icon>
            </a>
        </li>
    );
};

const LinkListItems = (data: LinkListProps) => {
    const list = data.items.map((item, index) => {
        return (
            <LinkListItem
                {...item}
                key={data.slice_type + data.primary.heading[0].text + data.primary.text[0] + index}
            />
        );
    });

    return <ul className="linklist__list">{list}</ul>;
};

const LinkListSlice = React.memo((props: LinkListProps) => {
    const { heading, text } = { ...props.primary };

    if (!heading) {
        return null;
    }

    return (
        <div className="linklist">
            <div className="linklist__content">
                <div className="linklist__wrapper">
                    <h3 className="linklist__heading">{heading[0].text}</h3>
                    <p className="linklist__text">{text[0]?.text}</p>
                </div>
            </div>
            <LinkListItems {...props} />
        </div>
    );
});

export type { LinkListProps };
export { LinkListSlice };
