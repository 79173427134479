import Prismic from "prismic-javascript";

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
export const apiEndpoint = "https://inspiredialoguecom.cdn.prismic.io/api/v2";

// use this in your DEV env
// export const apiEndpoint = "https://mw-reactjs-website.cdn.prismic.io/api/v2";

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
const accessToken = "";

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
    if (doc.type === "article_page") return `/articles/${doc.uid}`;
    if (doc.type === "dialogues") return `/dialogues`;
    if (doc.type === "dialogue_page") return `/dialogues/${doc.uid}`;
    if (doc.type === "our_story") return `/${doc.uid}`;
    if (doc.type === "support_the_dialogue") return `/${doc.uid}`;
    if (doc.type === "donate") return `/${doc.uid}`;
    if (doc.type === "contact_us") return `/${doc.uid}`;
    return "/";
};

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint, { accessToken });
