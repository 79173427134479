import React, { useEffect, useState } from "react";
import { prismicGetSingle } from "../../ApiHelpers/prismicGetSingle";
import Loader from "../../Components/Loader/Loader";
import { PrismicDocumentProps } from "../../types/PrismicDocumentProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import DonateSliceRenderer from "../../Slices/DonateSliceRenderer";
import MetaInfo from "../../Components/MetaInfo/MetaInfo";

interface DonateProps {
    title: PrismicTextProps;
    body: [PrismicSliceProps<[]>];
    meta_title: string;
    meta_description: string;
}

const DonatePage = () => {
    const [donatePageData, setDonatePageData] = useState<DonateProps>();
    const [id, setId] = useState<string>("");

    const fetchData = async () => {
        const prismicData: PrismicDocumentProps = await prismicGetSingle({
            id: "donate",
            options: {},
        });
        setDonatePageData(prismicData.data);
        setId(prismicData.id);
    };

    useEffect(() => {
        if (!donatePageData) {
            fetchData();
        }
    }, [donatePageData]);

    if (donatePageData) {
        return (
            <>
                <MetaInfo meta_title={donatePageData.meta_title} meta_description={donatePageData.meta_description} />
                <DonateSliceRenderer {...donatePageData} docId={id} />
                <div className="iframe-wrapper">
                    <iframe
                        title="iFrameDonate"
                        id="IframeDonate"
                        name="IframeDonate"
                        scrolling="no"
                        src="https://cafdonate.cafonline.org//Widget/5391?fix=0"
                        width="460px"
                        height="600px"
                    ></iframe>
                </div>
            </>
        );
    }
    return <Loader />;
};

export default DonatePage;
