import React, { FunctionComponent, useEffect, useState } from "react";

interface FadeProps {
    show: boolean;
    extraClass?: string;
}

const Fade: FunctionComponent<FadeProps> = ({ show, extraClass, children }) => {
    const [render, setRender] = useState(show);

    useEffect(() => {
        if (show) setRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if (!show) setRender(false);
    };

    return (
        <>
            {render && (
                <div
                    {...(extraClass && { className: extraClass })}
                    style={{ animation: `${show ? "fadeIn" : "fadeOut"} 0.33s` }}
                    onAnimationEnd={onAnimationEnd}
                >
                    {children}
                </div>
            )}
        </>
    );
};

export default Fade;
