import React from "react";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { PrismicImageProps } from "../../types/PrismicImageProps";
import { PrismicVideoProps } from "../../types/PrismicVideoProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";

/*
    Prismic always sends a video property, even when it is empty.
*/

type MediaProp = PrismicVideoProps | PrismicImageProps;

interface TextAndImageBlockProps extends PrismicSliceProps {
    primary: {
        headline: PrismicTextProps;
        text: [RichTextBlock];
        image: PrismicImageProps;
        image_placement: string;
        video: PrismicVideoProps;
    };
}

const TextImageBlockMedia = (data: MediaProp) => {
    if (data.type === "video") {
        const videoData = data as PrismicVideoProps;
        return (
            <div className="text-image-block__video">
                <div dangerouslySetInnerHTML={{ __html: videoData.html }} />
            </div>
        );
    } else {
        const imageData = data as PrismicImageProps;
        return <div className="text-image-block__image" style={{ backgroundImage: `url(${imageData.url})` }}></div>;
    }
};

const TextAndImageBlockSlice = React.memo((data: TextAndImageBlockProps) => {
    const { headline, text, image, image_placement, video } = {
        ...data.primary,
    };

    if (!headline || !text) {
        return null;
    }

    const imagePlacement = image_placement.toLowerCase();

    return (
        <div className="text-image-block">
            <div className={`text-image-block__media is-${imagePlacement}`}>
                <TextImageBlockMedia {...(Object.keys(video).length > 0 ? video : image)} />
            </div>
            <div className="text-image-block__content">
                <h3 className="text-image-block__headline">{headline[0].text}</h3>
                <div className="text-image-block__text">
                    <RichText render={text} htmlSerializer={htmlSerializer} />
                </div>
            </div>
        </div>
    );
});

export type { TextAndImageBlockProps };
export { TextAndImageBlockSlice };
