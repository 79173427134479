import React from "react";
import { Link } from "react-router-dom";
import { linkResolver } from "../../prismic-configuration";

interface ButtonProps {
    link: {};
    text: string;
    color?: string;
    size?: string;
}

const Button = (props: ButtonProps) => {
    const { link, text, color, size } = {
        ...props,
    };

    const buttonColor = color === "secondary" ? " button--sec" : " button--pri";
    const buttonSize = size === "big" ? " button--big" : "";
    const buttonClasses = "button" + buttonColor + buttonSize;

    return (
        <Link to={linkResolver(link)} className={buttonClasses}>
            {text}
        </Link>
    );
};

export default React.memo(Button);
