import React from "react";
import Fade from "../../hooks/useFadeAnimation";

const Loader = () => {
    return (
        <Fade show={true} key={"loaderKey"}>
            <div className="loader">
                <div className="loader__wrapper">
                    <div className="loader__item"></div>
                </div>
            </div>
        </Fade>
    );
};

export default React.memo(Loader);
