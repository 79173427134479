import React from "react";
import { isPrismicTextPropValid } from "../../ApiHelpers/validatePrismicTextProp";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { htmlSerializer } from "../../ApiHelpers/prismicHTMLSerializer";

interface ColumnTextProps extends PrismicSliceProps {
    primary: OneColumnProps | TwoColumnProps;
}

type OneColumnProps = {
    heading: PrismicTextProps;
    text: [RichTextBlock];
};

type TwoColumnProps = {
    headline_left: PrismicTextProps;
    headline_right: PrismicTextProps;
    text_left: [RichTextBlock];
    text_right: [RichTextBlock];
};

const OneColumnText = (props: OneColumnProps) => {
    const { heading, text } = { ...props };

    return (
        <div className="columnText">
            <div className="columnText__wrapper">
                <h4 className="columnText__heading">{heading[0]?.text}</h4>
                <div className="columnText__text">
                    <RichText render={text} htmlSerializer={htmlSerializer} />
                </div>
            </div>
        </div>
    );
};

const TwoColumnText = (props: TwoColumnProps) => {
    const { headline_left, headline_right, text_left, text_right } = { ...props };

    if (!isPrismicTextPropValid(headline_left) || !isPrismicTextPropValid(headline_right)) {
        return null;
    }

    return (
        <div className="columnText columnText--grid">
            <div className="columnText__wrapper">
                <h4 className="columnText__heading">{headline_left[0]?.text}</h4>
                <div className="columnText__text">
                    <RichText render={text_left} htmlSerializer={htmlSerializer} />
                </div>
            </div>
            <div className="columnText__wrapper">
                <h4 className="columnText__heading">{headline_right[0]?.text}</h4>
                <div className="columnText__text">
                    <RichText render={text_right} htmlSerializer={htmlSerializer} />
                </div>
            </div>
        </div>
    );
};

const ColumnTextSlice = React.memo((props: ColumnTextProps) => {
    if (props.slice_type === "two_column_text_block") {
        const twoColumnData = props.primary as TwoColumnProps;
        return <TwoColumnText {...twoColumnData} />;
    } else {
        const oneColumnData = props.primary as OneColumnProps;
        return <OneColumnText {...oneColumnData} />;
    }
});

export type { ColumnTextProps };
export { ColumnTextSlice };
