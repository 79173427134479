import React from "react";
import Fade from "../hooks/useFadeAnimation";
import { PrismicSliceProps } from "../types/PrismicSliceProps";
import { TextAndImageBlockSlice, TextAndImageBlockProps } from "./TextAndImageBlockSlice/TextAndImageBlockSlice";

interface DonateSliceRendererProps {
    body: [PrismicSliceProps<[]>];
    docId: string;
}

const DonateSliceRenderer = (data: DonateSliceRendererProps) => {
    const content = data.body.map((slice, index) => {
        switch (slice.slice_type) {
            case "text_and_image_block":
                const textAndImageBlockData = slice as TextAndImageBlockProps;
                return (
                    <Fade show={!!textAndImageBlockData} key={data.docId + slice.slice_type + index}>
                        <TextAndImageBlockSlice {...textAndImageBlockData} />
                    </Fade>
                );
            default:
                return null;
        }
    });

    return <>{content}</>;
};

export default DonateSliceRenderer;
