import React from "react";
import Fade from "../hooks/useFadeAnimation";
import { PrismicSliceProps } from "../types/PrismicSliceProps";
import { TextAndImageBlockProps, TextAndImageBlockSlice } from "./TextAndImageBlockSlice/TextAndImageBlockSlice";
import { CtaBlockProps, CtaBlockSlice } from "./CtaBlockSlice/CtaBlockSlice";
import { CtaColumnSlice, CtaColumnSliceProps } from "./CtaColumnSlice/CtaColumnSlice";
import { ImageListSlice, ImageListSliceProps } from "./ImageListSlice/ImageListSlice";

interface SupportTheDialogueSliceProps {
    body: [PrismicSliceProps<[]>];
    docId: string;
}

const SupportTheDialogueSliceRenderer = (data: SupportTheDialogueSliceProps) => {
    const content = data.body.map((slice, index) => {
        switch (slice.slice_type) {
            case "text_and_image_block":
                const textAndImageBlockData = slice as TextAndImageBlockProps;
                return (
                    <Fade show={!!textAndImageBlockData} key={data.docId + slice.slice_type + index}>
                        <TextAndImageBlockSlice {...textAndImageBlockData} />
                    </Fade>
                );
            case "cta_block":
                const CtaBlockData = slice as CtaBlockProps;
                return (
                    <Fade show={!!CtaBlockData} key={data.docId + slice.slice_type + index}>
                        <CtaBlockSlice {...CtaBlockData} {...data.docId} />
                    </Fade>
                );
            case "cta_column":
                const CtaColumnData = slice as CtaColumnSliceProps;
                return (
                    <Fade show={!!CtaColumnData} key={data.docId + slice.slice_type + index}>
                        <CtaColumnSlice {...CtaColumnData} id={data.docId} />
                    </Fade>
                );
            case "image_list":
                const ImageListData = slice as ImageListSliceProps;
                return (
                    <Fade show={!!ImageListData} key={data.docId + slice.slice_type + index}>
                        <ImageListSlice {...ImageListData} />
                    </Fade>
                );
            default:
                return null;
        }
    });

    return <>{content}</>;
};

export default SupportTheDialogueSliceRenderer;
