import React from "react";
import { PrismicTextProps } from "../../types/PrismicTextProps";
import { PrismicImageProps } from "../../types/PrismicImageProps";
import { PrismicSliceProps } from "../../types/PrismicSliceProps";

type ImageItem = {
    gallery_image: PrismicImageProps;
    image_captions: PrismicTextProps;
};

interface ImageGalleryProps extends PrismicSliceProps<ImageItem> {
    primary: {
        name_of_the_gallery: PrismicTextProps;
    };
}

const ListOfImages = (data: { items: ImageItem[]; id: string }) => {
    const listItems = data.items.map((item, index) => {
        const { url, alt } = { ...item.gallery_image };

        return (
            <div className="image-gallery__item" key={data.id + url + index}>
                <img src={url} alt={alt ? alt : ""}></img>
            </div>
        );
    });
    return <div className="image-gallery__list">{listItems}</div>;
};

const ImageGallerySlice = (data: ImageGalleryProps, id: string) => {
    const { name_of_the_gallery } = {
        ...data.primary,
    };

    if (!data.items.length) {
        return null;
    }

    return (
        <div className="image-gallery">
            <h3 className="image-gallery__title">{name_of_the_gallery[0]?.text}</h3>

            <ListOfImages {...data} id={id} />
        </div>
    );
};

export type { ImageGalleryProps };
export { ImageGallerySlice };
